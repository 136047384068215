import React from 'react';
import '../styles/home.css';

const Home = () => {
  return (
    <div className="home-container">
      {/* <h2 className="title">Reclutas</h2> */}
      <img src="/vectors/logo1.png" alt="Description" id='big-logo'/>
      <p className="description">¿Preparados?</p>
      <p className="description">El reclutamiento ya empezó</p>
    </div>
  );
};

export default Home;
