import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/navbar.css';

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li><Link to="/"><FontAwesomeIcon icon={faHome} className='icon' />Inicio</Link></li>
        <li><Link to="/about"><FontAwesomeIcon icon={faInfoCircle} className='icon'/>Acerca</Link></li>
        <li><Link to="/contact"><FontAwesomeIcon icon={faEnvelope} className='icon'/>Contacto</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
